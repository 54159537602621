<template>
  <Browse
    :columns="[
      'name',
      'quantiry',
      'unit_of_time',
      'note',
    ]"
    :formats="{
      quantiry: 'Integer4',
      unit_of_time: 'Select*',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="validities"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
